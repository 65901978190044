<template>
  <v-container>
    <v-row class="pa-0 ma-0">
      <v-col cols="12" class="pa-0 ma-0" v-show="closestTimeId">
        <h4>{{ $t("CLOSEST_USER_APPOINTMENT") }}</h4>
      </v-col>
      <v-col cols="12" class="pa-0 ma-0" v-show="closestTimeId">
        <v-btn
            outlined
            large
            color="primary"
            class="w-100 mt-4 text-center f15 white--text"
            @click="submitPickedTime(closestTimeId)"
        >
          {{ closestButtonString }}
        </v-btn>
      </v-col>
      <v-divider class="mt-3" v-show="closestTimeId"/>
      <v-col cols="12" class="pa-0 ma-0">
        <v-menu
            v-model="selectedAppointmentDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="selectedAppointmentDateInPersian"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
              ref="picker"
              elevation="5"
              color="primary"
              :events="functionEvents"
              v-model="selectedAppointmentDate"
              @click:date="selectedDateChanged"
              @click:month="selectedDateMonthChanged"
              :event-color="(date) => (date[9] % 2 ? 'red' : 'yellow')"
              :min="new Date().toISOString().substring(0, 10)"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <span v-if="timeSheetDetails.length">{{ $t('selected user date') }}</span>
      <v-col
          class="text-center"
          cols="12"
          v-if="timeSheetDetails.length === 0"
      >
        <h6>{{ $t("NO_USER_APPOINTMENT_FOR_DATE") }}</h6>
      </v-col>
      <v-col
          v-show="item"
          v-for="item in timeSheetDetails"
          :key="item.id"
          class="pa-1 ma-0"
          cols="6"
      >
        <v-btn
            outlined
            large
            color="primary"
            class="w-100 mt-4 text-center f15 white--text"
            @click="submitPickedTime(item.id)"
            :disabled="item.userAppointment !== null"
        >
          {{ getButtonString(item) }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import apiService from "@/http/ApiService.js";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";

moment.updateLocale("fa", fa);
moment.loadPersian();

export default {
  name: "appointment-time-selection",
  props: {
    appointmentTypeSelected: {type: String, required: true},
    Profile: {type: String, required: true},
  },
  data() {
    return {
      appointmentType: this.appointmentTypeSelected,
      selectedAppointmentDate: null,
      selectedAppointmentDateInPersian: null,
      selectedAppointmentDateMenu: false,
      timeSheetDetails: [],
      closestButtonString: null,
      closestTimeId: null,

      page: 0,
      pageSize: 500,
    };
  },
  beforeMount() {
    this.selectedAppointmentDate = moment().format("YYYY-MM-DD");
    this.selectedAppointmentDateInPersian = moment(this.selectedAppointmentDate).locale('fa').format('jYYYY/jMM/jDD');
  },
  mounted() {
    this.updateEvents();
  },

  watch: {
    selectedAppointmentDate: function (newVal) {
      apiService.getDoctorTimesheetDetailsByDoctorIdAndDateAndSessionType(
          this.$route.params.id,
          newVal,
          this.appointmentType
      )
          .then((response) => {
            this.selectedAppointmentDateInPersian = moment(newVal).locale('fa').format('jYYYY/jMM/jDD');
            if (response.data.id) {
              this.timeSheetDetails = response.data.timeSheetDetails
                  .slice()
                  .sort(function (a, b) {
                    return a.fromTime.localeCompare(b.fromTime);
                  });
              if (!this.closestTime && this.timeSheetDetails?.length > 0) {
                const closestTime = this.timeSheetDetails.find(
                    (item) => {
                      item.fromTime.split(":")[0] > moment().hour() &&
                      !item.userAppointment
                      console.log(item.fromTime)
                    }
                );
                this.closestTimeId = closestTime.id;
                this.closestButtonString = `${moment(
                    response.data.valueDate
                ).format("dddd jD jMMMM")} ${
                    closestTime.fromTime.split(":").slice(0, 2).join(":")
                } - ${closestTime.toTime.split(":").slice(0, 2).join(":")}`;
              }
            } else {
              this.timeSheetDetails = [];
              if (!this.closestTime) {
                apiService
                    .getClosestEmptyTimesheet(
                        this.$route.params.id,
                        this.appointmentType
                    )
                    .then((response) => {
                      const closestTime = response.data;
                      if (closestTime) {
                        this.closestTimeId = closestTime.timesheetDetailId;
                        this.closestButtonString = `${moment(
                            closestTime.valueDate
                        ).format("dddd jD jMMMM")} ${
                            closestTime.fromTime.split(":").slice(0, 2).join(":")
                        } - ${closestTime.toTime.split(":").slice(0, 2).join(":")}`;
                      }
                    })
                    .catch((error) => {
                      console.error(error);
                    });
              }
            }
          })
          .catch((error) => {
            console.error(error);
          });
    },
  },
  methods: {
    submitPickedTime(timeId) {
      this.$emit("interface", timeId);
    },
    getButtonString(timeSheetDetail) {
      return `${timeSheetDetail.fromTime.split(":").slice(0, 2).join(":")} - ${
          timeSheetDetail.toTime.split(":").slice(0, 2).join(":")
      }`;
    },

    selectedDateChanged() {
      const d = moment(this.selectedDate);
      if (d.year() !== this.yearKeeper || d.month() !== this.monthKeeper) {
        this.updateEvents(this.selectedDate);
        this.yearKeeper = d.year();
        this.monthKeeper = d.month();
      }
    },
    getTimeSheetsOfDate() {
      const found = this.timesheets.filter(
          (t) => this.selectedDate === t.valueDate
      );
      if (found.length > 0 && found[0].timeSheetDetails.length > 0) {
        return found[0].timeSheetDetails.slice().sort(function (a, b) {
          return a.fromTime.localeCompare(b.fromTime);
        });
      }
      return [];
    },
    updateEvents(date) {
      apiService
          .getDoctorTimesheetByUser(
              this.Profile.id,
              this.getFirstDayOfSelectedMonth(date),
              this.getLastDayOfSelectedMonth(date),
              this.page,
              this.pageSize
          )
          .then((response) => {
            this.timesheets = response.data;
            this.selectedInfo = this.timesheets.length ? this.timesheets[0].timeSheetDetails[0] : {}
          })
          .catch((err) => {
            this.$swal({
              icon: "warning",
              text: err ? err.response.data.detail : this.$t("Error"),
              confirmButtonText: this.$t('OK'),
            });
          });
    },
    functionEvents(date) {
      const found = this.timesheets.filter((t) => date === t.valueDate);
      if (found.length > 0) {
        const colors = [];
        const timesheet = found[0];
        const hasUnregistered = timesheet.timeSheetDetails.some(
            (td) => td.userAppointment === null
        );
        const hasRegistered = timesheet.timeSheetDetails.some(
            (td) => td.userAppointment !== null
        );
        const passed = moment(found[0].valueDate, "YYYY-MM-DD").isBefore(
            moment()
        );
        if (hasRegistered) {
          colors.push("green");
        }
        if (hasUnregistered && passed) {
          colors.push("grey");
        } else if (hasUnregistered && !passed) {
          colors.push("blue");
        }
        return colors;
      }
      return false;
    },


    getFirstDayOfSelectedMonth(date) {
      return moment(date).startOf("month").format("YYYY-MM-DD");
    },
    getLastDayOfSelectedMonth(date) {
      return moment(date).endOf("month").format("YYYY-MM-DD");
    },

    selectedDateInJalali() {
      return moment(this.selectedDate).format("jYYYY/jM/jD");
    },
    selectedDateMonthChanged(date) {
      this.updateEvents(date + this.selectedDate.substring(7));
    },

  },
};
</script>