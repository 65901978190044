<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-text-field
          readonly
          outlined
          type="text"
          hide-details="auto"
          class="text-center"
          :label="$t('APPOINTMENT_TYPE')"
          :value="$t(appointmentType)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          readonly
          outlined
          type="text"
          hide-details="auto"
          class="text-center"
          :label="$t('APPOINTMENT_DATE')"
          v-model="appointmentTime"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          readonly
          outlined
          type="text"
          hide-details="auto"
          class="text-center"
          v-mask="currencyMask"
          :suffix="$t('RIAL')"
          :label="$t('USER_APPOINTMENT_PER_HOUR')"
          v-model="drConsultantAmount"
        />
      </v-col>
      <v-col>
        <span>

          {{ consultantPaymentType === 'BEFORE_START_SESSION' ? $t('Payment before the session') : $t('Payment after the session') }}
        </span>
      </v-col>
      <v-col cols="12">
        <v-textarea
            rows="2"
            counter
            maxlength="500"
            outlined
            type="text"
            hide-details="auto"
            class="text-center"
            :label="$t('Description')"
            v-model="description"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import apiService from "@/http/ApiService.js";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";
import {description} from "mapir-vue/docs_source/.vuepress/config";

moment.updateLocale("fa", fa);
moment.loadPersian();

const currencyMask = createNumberMask({
  prefix: "",
  allowDecimal: false,
  includeThousandsSeparator: true,
  allowNegative: false,
});

export default {
  name: "appointment-confirmation",
  props: {
    appointmentTypeSelected: { type: String, required: true },
    consultantAmount: { type: Number, required: true },
    timeSelectedId: { type: Number, required: true },
    consultantPaymentType: { type: String, required: true },
  },
  data() {
    return {
      currencyMask,
      appointmentType: this.appointmentTypeSelected,
      appointmentTime: "",
      drConsultantAmount: this.consultantAmount,
      appointmentId: this.timeSelectedId,
      description: '',
    };
  },
  mounted() {
    apiService
      .getDetailedTimeSheetDetail(this.appointmentId)
      .then((response) => {
        const detailed = response.data;
        if (detailed.userId !== null) {
          console.error("Appointment has been selected!");
          this.$swal({
            icon: "info",
            text: this.$t("TIME_IS_RESERVED_BEFORE"),
            confirmButtonText: this.$t("OK"),
          });
        } else {
          this.appointmentTime = `${moment(detailed.valueDate).format(
            "dddd jD jMMMM"
          )} ${detailed.fromTime.split(":").slice(0, 2).join(":")} - ${
            detailed.toTime.split(":").slice(0, 2).join(":")
          }`;
          this.$emit("interface", this.appointmentTime);
        }
      })
      .catch((error) => {
        console.error(error);
        this.$swal({
          icon: "error",
          text: error ? error.response.data.message : this.$t("Error"),
          confirmButtonText: this.$t("OK"),
        });
      });
  },
  methods: {
    description() {
      return description
    }
  },
};
</script>