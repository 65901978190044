<template>
  <v-container>
    <v-row class="pa-0 ma-0">
      <v-col cols="12" class="pa-0 ma-0">
        <p class="f14 text-danger text-justify my-4">
          {{
            $t(
              "In critical situations such as any child abuse, spouse abuse or thoughts Suicide without patience Call 123(iran) And 911 (USA) Suicide Emergency"
            )
          }}
        </p>
      </v-col>
    </v-row>
    <v-divider />
    <v-row class="pa-0 ma-0">
      <v-col cols="12" class="pa-0 ma-0">
        <p class="text-justify my-4" style="font-size: 14px">
          {{ $t("USER_APPOINTMENT_REQUEST_PARAGRAPH") }}
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

export default {
  name: "appointment-request",
  data() {
    return {};
  },
  beforeMount() {},
  methods: {},
};
</script>